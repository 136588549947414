import { ThemeOverride } from '@chakra-ui/react';

const fontSizes = {
  '2xs': '0.625rem', //   10
  xs: '0.75rem', //       12
  sm: '0.875rem', //      14
  md: '1rem', //          16
  lg: '1.125rem', //      18
  xl: '1.25rem', //       20
  '2xl': '1.5rem', //     24
  '2.5xl': '1.75rem', //  28
  '3xl': '2rem', //       32
  '4xl': '2.5rem', //     40
  '5xl': '3rem', //       48
  '6xl': '3.5rem', //     56
  '7xl': '4rem', //       64
  '8xl': '4.5rem', //     72
  '9xl': '5rem', //       80
  '10xl': '6rem', //      96
};

export const typography: Pick<ThemeOverride, 'fontSizes' | 'fonts' | 'lineHeights'> = {
  fontSizes,
  fonts: {
    body: '"IBM Plex Sans", sans-serif',
    heading: '"Bios", sans-serif',
    hyperwaveOne: '"Hyperwave One", sans-serif',
  },
};
