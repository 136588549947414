import { CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { Kong } from '@cyberkongz/database';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { ChainEnhanced } from '../config';

export function getKongzContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).Kongz;
}

export function useKongEnhancedData(kong?: Kong) {
  const { address } = useAccount();

  const { isOwner } = useMemo(() => {
    if (!kong) {
      return { isOwner: false };
    }

    const { owner_address } = kong;

    const isOwner = address?.toLowerCase() === owner_address?.toLowerCase();

    return {
      isOwner,
    };
  }, [address, kong]);

  return {
    isOwner,
  };
}
