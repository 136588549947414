import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useBlockNumber } from 'wagmi';

export function useWatchReadContract({ queryKey }: { queryKey?: QueryKey }) {
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey });
  }, [blockNumber]);
}
