import { formatUnits, parseUnits } from 'viem';

const DEFAULT_DIGITS = 2;
const DEFAULT_DECIMALS = 18;

export function formatAddress(address: string) {
  return `${address.slice(0, 4)}...${address.slice(-5)}`;
}

export function formatAddressLong(address: string) {
  return `${address.slice(0, 8)}...${address.slice(-9)}`;
}

export function formatAddressLongMenu(address: string) {
  return `${address.slice(0, 6)}...${address.slice(-7)}`;
}

export function formatTxHash(txHash: string, characters: number | undefined = 6) {
  return `${txHash.slice(0, characters)}...${txHash.slice(-(characters + 1))}`;
}

export function formatEnsMenu(ens: string) {
  return ens.length < 25 ? ens : `${ens.slice(0, 10)}...${ens.slice(-12)}`;
}

export function amountFromBigNumber(amount: bigint, decimals = DEFAULT_DECIMALS) {
  return formatUnits(amount, decimals);
}

// in case of inputs amount could be empty string "" so needs '0' as fallback
export function amountToBigNumber(amount: string | number, decimals = DEFAULT_DECIMALS) {
  return parseUnits(amount.toString() || '0', decimals);
}

type FormatCryptoBalanceOptions = { decimals?: number; digits?: number };

const formatCryptoBalanceDefaults: Required<FormatCryptoBalanceOptions> = {
  decimals: DEFAULT_DECIMALS,
  digits: DEFAULT_DIGITS,
};

export function formatCryptoBalance(
  balance?: bigint | string,
  options: FormatCryptoBalanceOptions = {}
) {
  const { decimals, digits } = { ...formatCryptoBalanceDefaults, ...options };

  if (balance !== undefined) {
    const balanceBigNumber =
      typeof balance === 'string' ? amountToBigNumber(balance, decimals) : balance;

    return Number(amountFromBigNumber(balanceBigNumber, decimals)).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
    });
  }

  return undefined;
}
