// @ts-nocheck

type PickByValue<T, V> = Pick<T, { [K in keyof T]: T[K] extends V ? K : never }[keyof T]>;

type Entries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]];
}[keyof T][];

// TO DO IMPROVE TYPINGS IN HERE
export function getAbsoluteRoutes<T extends {}>(baseRoutes: T, routesBaseUrl: string) {
  return (Object.entries(baseRoutes) as Entries<T>).reduce((routes, entry) => {
    const [key, value] = entry;

    // nested path
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key_nested, value_nested]) => {
        if (!routes[key]) {
          routes[key] = {};
        }

        routes[key][key_nested] = `${routesBaseUrl}${value_nested}`;
      });
    } else {
      routes[key] =
        typeof value === 'string'
          ? `${routesBaseUrl}${value}`
          : (...args: any) => `${routesBaseUrl}${value(...args)}`;
    }

    return routes;
  }, {} as T);
}
