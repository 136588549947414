import { ERC_1155_BY_KEY } from '@cyberkongz/config-assets';
import { ChainType, CONTRACTS_BY_NETWORK, ZERO_ADDRESS } from '@cyberkongz/config-blockchain';
import { useMemo } from 'react';
import { Address } from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useWatchReadContract } from '.';
import { useIsApprovedForAll, UseIsApprovedForAllSharedProps } from './useAllowance';
import { useChainEnhanced } from './useChainEnhanced';

export function getCharmzContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).Charmz;
}

export function getCharmzPolygonContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).CharmzPolygon;
}

export function useCharmBalance({ charmId }: { charmId: number }) {
  const { address } = useAccount();
  const { chain, mainnetChain } = useChainEnhanced();

  const { data: charmBalance, queryKey } = useReadContract({
    ...getCharmzContract(chain),
    functionName: 'balanceOf',
    args: [address || ZERO_ADDRESS, BigInt(charmId)],
    chainId: mainnetChain?.id,
    query: {
      enabled: !!mainnetChain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    charmBalance,
  };
}

export function useCharmPolygonBalance({ charmId }: { charmId: number }) {
  const { address } = useAccount();
  const { chain, polygonChain } = useChainEnhanced();

  const { data: charmBalance, queryKey } = useReadContract({
    ...getCharmzPolygonContract(chain),
    functionName: 'balanceOf',
    args: [address || ZERO_ADDRESS, BigInt(charmId)],
    chainId: polygonChain?.id,
    query: {
      enabled: !!polygonChain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    charmBalance,
  };
}

const CHARMZ_IDS = [
  ERC_1155_BY_KEY.shardz.tokenId,
  ERC_1155_BY_KEY.cyber_fragment.tokenId,
  ERC_1155_BY_KEY.rainbow_crystal.tokenId,
  ERC_1155_BY_KEY.promethean_relic.tokenId,
];

const CHARMZ_IDS_BN = CHARMZ_IDS.map((id) => BigInt(id));

export function useCharmzBalancesPolygonArgs(address?: Address) {
  return [CHARMZ_IDS.map((_) => address || ZERO_ADDRESS), CHARMZ_IDS_BN] as readonly [
    readonly Address[],
    readonly bigint[]
  ];
}

export function useCharmzBalancesPolygon() {
  const { address } = useAccount();
  const { chain, polygonChain } = useChainEnhanced();

  const { data: balances, queryKey } = useReadContract({
    ...getCharmzPolygonContract(chain),
    functionName: 'balanceOfBatch',
    args: useCharmzBalancesPolygonArgs(address),
    chainId: polygonChain?.id,
    query: {
      enabled: !!chain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    balances,
  };
}

export function useCharmzAllowance({
  chainType,
  ...rest
}: UseIsApprovedForAllSharedProps & {
  chainType: ChainType;
}) {
  const { mainnetChain, polygonChain } = useChainEnhanced();

  const { contractAddress, chain } = useMemo(
    () => ({
      contractAddress:
        chainType === 'mainnet'
          ? getCharmzContract(mainnetChain).address
          : getCharmzPolygonContract(polygonChain).address,
      chain: chainType === 'mainnet' ? mainnetChain : polygonChain,
    }),
    [chainType]
  );

  return useIsApprovedForAll({
    contractAddress,
    chain,
    ...rest,
  });
}
