import { extendTheme, ThemeOverride } from '@chakra-ui/react';

import { colors } from './colors';
import { components } from './components';
import { textStyles } from './textStyles';
import { typography } from './typography';

const baseTheme: ThemeOverride = {
  breakpoints: {
    sm: '48em',
    md: '64em',
    lg: '80em',
  },
  borders: {
    thin: '1px solid var(--chakra-colors-neutral-700)',
    thinWhite: '1px solid var(--chakra-colors-white)',
    mediumWhite: '2px solid var(--chakra-colors-white)',
  },
  zIndices: {
    modal: 1400,
    menu: 6,
    footer: 5,
  },
  styles: {
    global: {
      html: {
        scrollPaddingTop: 'var(--scroll-padding-top)',
        '--w3m-z-index': 'var(--chakra-zIndices-toast)!important',
      },
      body: {
        background: 'background',
        color: 'text',
        fontSize: ['sm', 'md'],
        fontWeight: 'normal',
        fontFamily: 'body',
      },
      strong: {
        fontWeight: 'medium',
      },
      svg: {
        fontSize: '0px',
      },
    },
  },
  colors,
  components,
  ...typography,
  ...textStyles,
};

export const theme = extendTheme(baseTheme);
