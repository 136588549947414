// This is extracted from Web3Provider as there were cyclic dependencies cause of ConnectWalletModal import
// it was breaking HotReloading while saving files in ui lib
import { ChainType } from '@cyberkongz/config-blockchain';
import { createContext, useContext } from 'react';

type Web3ContextState = {
  openConnectWalletModal: (expectedChain?: ChainType) => void;
  handleSwitchNetwork: () => void;
  supportedChainTypes: ChainType[];
};

export const Web3Context = createContext<Web3ContextState | undefined>(undefined);

export function useWeb3Context() {
  const context = useContext(Web3Context);

  if (context === undefined) {
    throw new Error('useWeb3Context must be used within a Web3Provider');
  }

  return context;
}
