import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';

export function DefaultSEOTags({
  siteUrl,
  title,
  favIconColorSchemeEnabled,
}: {
  siteUrl: string;
  title?: string;
  favIconColorSchemeEnabled?: boolean;
}) {
  const OG_IMG = `${siteUrl}/static/og.jpg`;

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {favIconColorSchemeEnabled ? (
          <>
            <link
              href="/static/favicon_light.png"
              rel="shortcut icon"
              media="(prefers-color-scheme: light)"
            />
            <link
              href="/static/favicon_dark.png"
              rel="shortcut icon"
              media="(prefers-color-scheme: dark)"
            />
          </>
        ) : (
          <link rel="shortcut icon" href="/static/favicon.png" />
        )}
      </Head>
      <NextSeo
        title={title}
        additionalMetaTags={[
          {
            property: 'og:image',
            content: OG_IMG,
            keyOverride: 'og-image',
          },
          {
            name: 'twitter:image',
            content: OG_IMG,
            keyOverride: 'twitter-image',
          },
        ]}
      />
    </>
  );
}

export type AppPageSEOTags<T> = NextSeoProps & {
  title?: string;
  pageKey?: T;
  imageUrl?: string;
  imageAlt?: string;
};

export function PageSEOTags<T extends string>(
  props: NextSeoProps & {
    siteUrl: string;
    defaultImageUrl: string;
    defaultImageAlt: string;
    defaultTitle: string;
    seoTagsMapping: { [key in T]: { title: string; description: string } };
    pageKey?: T;
    imageUrl?: string;
    imageAlt?: string;
  }
) {
  const {
    siteUrl,
    defaultImageUrl,
    defaultImageAlt,
    defaultTitle,
    title: _title,
    description: _description,
    seoTagsMapping,
    pageKey,
    imageUrl: _imageUrl,
    imageAlt: _imageAlt,
    ...rest
  } = props;
  const { asPath } = useRouter();
  const title = pageKey ? seoTagsMapping[pageKey].title : _title || defaultTitle;
  const description = pageKey ? seoTagsMapping[pageKey].description : _description;
  const imageUrl = _imageUrl || defaultImageUrl;
  const imageAlt = _imageAlt || defaultImageAlt;

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        locale: 'en_US',
        url: `${siteUrl}${asPath}`,
        site_name: title,
        title,
        description,
        images: [
          {
            url: imageUrl,
            alt: imageAlt,
          },
        ],
      }}
      twitter={{
        cardType: 'summary_large_image',
      }}
      additionalMetaTags={[
        {
          property: 'og:image',
          content: imageUrl,
          keyOverride: 'og-image',
        },
        {
          name: 'twitter:image',
          content: imageUrl,
          keyOverride: 'twitter-image',
        },
      ]}
      {...rest}
    />
  );
}
