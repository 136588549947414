import { AccordionButton, AccordionButtonProps, SystemStyleObject } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { TRANSITIONS } from '../theme';
import { ChevronDownIcon } from './icons';

export function AccordionButtonWrapper({
  sx,
  _expanded,
  _hover,
  children,
  sxArrow,
  ...rest
}: PropsWithChildren & AccordionButtonProps & { sxArrow?: SystemStyleObject }) {
  return (
    <AccordionButton
      sx={{
        alignItems: 'center',
        p: 0,
        justifyContent: 'space-between',
        textAlign: 'left',
        ...sx,
      }}
      _expanded={{
        // TO DO BETTER WAY INSTEAD OF CLASSNAMES?
        '.accordion-icon': {
          transform: 'rotateZ(180deg)',
        },
        ..._expanded,
      }}
      _hover={{ bg: 'none', ..._hover }}
      {...rest}
    >
      {children}
      <ChevronDownIcon
        className="accordion-icon"
        ml={3}
        transition={TRANSITIONS.standard}
        sx={sxArrow}
      />
    </AccordionButton>
  );
}
