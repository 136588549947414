import { useEffect, useMemo, useRef, useState } from 'react';

export function useDropdownWithHover() {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const isTouchDevice = typeof window !== 'undefined' && 'ontouchstart' in window;
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const handleOnMouseEnter = useMemo(
    () => (!isTouchDevice ? () => setDropdownOpened(true) : undefined),
    [isTouchDevice]
  );

  const handleOnMouseLeave = useMemo(
    () => (!isTouchDevice ? () => setDropdownOpened(false) : undefined),
    [isTouchDevice]
  );

  function handleOnClick() {
    setDropdownOpened(!dropdownOpened);
  }

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setDropdownOpened(false);
      }
    }

    if (dropdownOpened !== false) {
      document.addEventListener('click', handleClickOutside);

      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [dropdownOpened]);

  useEffect(() => {
    function handleScroll() {
      setDropdownOpened(false);
    }

    if (dropdownOpened !== false) {
      document.addEventListener('scroll', handleScroll);

      return () => document.removeEventListener('click', handleScroll);
    }
  }, [dropdownOpened]);

  return {
    dropdownOpened,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnClick,
    dropdownRef,
  };
}
