import { KollectionType } from '@cyberkongz/api-interfaces';
import { CONTRACTS_L1_MAINNET, CONTRACTS_L2_POLYGON } from '@cyberkongz/config-blockchain';

import { getAbsoluteRoutes } from './utils';

const KOLLECTION_LINK = (kollection: KollectionType) => `/kollections/${kollection}`;

const BLUEPRINT_SLUGS = [
  'vision',
  'ecosystem',
  'metaverse',
  'community',
  'irl',
  'social-impact',
] as const;

export type BlueprintSlugType = typeof BLUEPRINT_SLUGS[number];

export const TERMS_SLUGS = [
  'terms-and-conditions',
  'privacy-policy',
  'cookies-policy',
  'genkai-saga-disclosures',
  'kongz-kreator-program',
  'ck-store-and-emaki-policy',
] as const;

export type TermsSlugType = typeof TERMS_SLUGS[number];

const LANDING_BASE_URL = 'https://www.cyberkongz.com';

export const LANDING_ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  FAQ: '/faq',
  KOLLECTIONS: '/kollections',
  KOLLECTION: KOLLECTION_LINK,
  KONG: (kollectionType: KollectionType, id: string | number) =>
    `${KOLLECTION_LINK(kollectionType)}/${id}`,
  BLOG: '/blog',
  BLOG_POST: (slug: string) => `/blog/post/${slug}`,
  BANANA: '/banana',
  BLOG_CATEGORY: (slug: string) => `/blog/category/${slug}`,
  TERMS: (terms: TermsSlugType) => `/terms/${terms}`,
  BLUEPRINT: '/blueprint',
  BLUEPRINT_TAB: (tab: BlueprintSlugType) => `/blueprint/${tab}`,
  PLAY_AND_KOLLECT: '/play-and-kollect',
  NIAKEA: '/niakea',
  PR_KIT: '/press',
  INTERACTIVE_ROADMAP: '/roadmap',

  MERCH_UNDER_ARMOUR:
    'https://teamlocker.squadlocker.com/#/lockers/up-front-a-cyberkongz-collection',

  GUARDIAN_CONTRACT_ARTICLE:
    'https://medium.com/@owl_of_moistness/on-chain-2fa-is-that-a-thing-95f3fff7f1cc',

  BANANA_ETH: `https://etherscan.io/token/${CONTRACTS_L1_MAINNET.Banana.address}#code`,
  BANANA_POLY: `https://polygonscan.com/token/${CONTRACTS_L2_POLYGON.BananaPolygon.address}#code`,

  KREATOR_PROGRAM: '/kreator-program',
  API_KREATOR_PROGRAM: '/api/kreator-program',
};

export const LANDING_ROUTES_ABSOLUTE = getAbsoluteRoutes(LANDING_ROUTES, LANDING_BASE_URL);
