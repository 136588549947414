import { APIKongVX, getKongVXOwnerData } from '@cyberkongz/api-interfaces';
import { ChainType, CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useIsApprovedForAll, UseIsApprovedForAllSharedProps } from './useAllowance';
import { useChainEnhanced } from './useChainEnhanced';

export function getKongzVXContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).KongzVX;
}

export function getKongzVXPolygonContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).KongzVXPolygon;
}

export function getKongzVXRoninContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).KongzVXRonin;
}

export function useKongVXEnhancedData(kong?: APIKongVX) {
  const { address } = useAccount();
  const { isOwner, owner, chain, isLocked } = useMemo(() => {
    if (!kong) {
      return { isOwner: false, owner: '', chain: undefined, isLocked: false };
    }

    const { lock_registry } = kong;

    const { chain, owner } = getKongVXOwnerData(kong);

    const isOwner = owner.toLowerCase() === address?.toLowerCase();
    const isLocked = lock_registry.length > 0;

    return { isOwner, owner, chain, isLocked };
  }, [address, kong]);

  return { isOwner, owner, chain, isLocked };
}

export function useKongzVXAllowance({
  chainType,
  ...rest
}: UseIsApprovedForAllSharedProps & {
  chainType: ChainType;
}) {
  const { mainnetChain, polygonChain } = useChainEnhanced();

  const { contractAddress, chain } = useMemo(
    () => ({
      contractAddress:
        chainType === 'mainnet'
          ? getKongzVXContract(mainnetChain).address
          : getKongzVXPolygonContract(polygonChain).address,
      chain: chainType === 'mainnet' ? mainnetChain : polygonChain,
    }),
    [chainType]
  );

  return useIsApprovedForAll({
    contractAddress,
    chain,
    ...rest,
  });
}
