import { Grid, Heading } from '@chakra-ui/react';

import { BaseModalProps, Modal } from '../Modal';
import { connectWalletModalStyles, WalletList } from './ConnectWalletModal';

export function SwitchNetworkModal({ isOpen, onClose }: BaseModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styles={connectWalletModalStyles}>
      <Grid gap={[6, 10]}>
        <Heading textAlign="center" size="sm">
          Switch Network
        </Heading>
        <WalletList onClose={onClose} mode="switch" />
      </Grid>
    </Modal>
  );
}
