import { getAbsoluteRoutes } from './utils';

const APP_BASE_URL = 'https://app.cyberkongz.com';

export const JUNGLE_ADVENTURE_SIDEBARS = ['ms-kongz', 'portal', 'temple', 'treasury'] as const;

export type JungleAdventureSidebar = typeof JUNGLE_ADVENTURE_SIDEBARS[number];

export const PROFILE_ASSET_TYPES = ['armory', 'charmz', 'consumables'] as const;

export type ProfileAssetType = typeof PROFILE_ASSET_TYPES[number];

const JUNGLE_ADVENTURE_SIDEBAR = (sidebar: JungleAdventureSidebar) =>
  `/jungle-adventure/${sidebar}`;

const PROFILE_BASE_URL = '/profile';

export const APP_ROUTES = {
  HOME: '/',
  CLAIM: '/claim',
  JUNGLE_ADVENTURE: '/jungle-adventure',
  JUNGLE_ADVENTURE_SIDEBAR: JUNGLE_ADVENTURE_SIDEBAR,
  PROFILE_KOLLECTION: `${PROFILE_BASE_URL}/kollection`,
  PROFILE_ASSETS: (type?: ProfileAssetType) =>
    `${PROFILE_BASE_URL}/assets${type ? `?type=${type}` : ''}`,
  PROFILE_PUBLIC_ADDRESS: (address: string) => `${PROFILE_BASE_URL}/${address}`,
  UNIQLY_REDEEM_HOODIE: (id: string) =>
    `https://www.uniqly.io/items/nft/0xa7b974d3c6a9e46bae10c2dd599966be126cbdbe/${id}`,

  REPLAY_MYSTER_BOX: ({
    transactionHash,
    testnet,
  }: {
    transactionHash?: string;
    testnet?: boolean;
  }) =>
    `/replay/open-mystery-box?transactionHash=${transactionHash}${testnet ? '&testnet=true' : ''}`,

  LOOTRUSH_KONGZ_VX: 'https://www.lootrush.com/collections/cyberkongz---vx-(ron)406137',
};

export const APP_ROUTES_ABSOLUTE = getAbsoluteRoutes(APP_ROUTES, APP_BASE_URL);
