import { SystemStyleObject, ThemeOverride } from '@chakra-ui/react';

import { pxToRem } from './utils';

export const paragraphTextSizes = ['p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl'] as const;

type PrimaryTextSize = typeof paragraphTextSizes[number];

export const paragraphSizeTextStyles: { [key in PrimaryTextSize]: SystemStyleObject } = {
  'p-xs': {
    fontSize: 'xs',
    lineHeight: '1rem',
    letterSpacing: pxToRem(0.32),
  },
  'p-sm': {
    fontSize: ['xs', 'sm'],
    lineHeight: '1.25rem',
    letterSpacing: pxToRem(0.16),
  },
  'p-md': {
    fontSize: ['sm', 'md'],
    lineHeight: '1.5rem',
  },
  'p-lg': {
    fontSize: ['md', 'lg'],
    lineHeight: ['1.5rem', '1.75rem'],
  },
  'p-xl': {
    fontSize: ['md', 'xl'],
    lineHeight: ['1.5rem', '1.875rem'],
  },
};

export const textStyles: ThemeOverride = {
  textStyles: {
    ...paragraphSizeTextStyles,
  },
};
