import { CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { useEffect } from 'react';

import { ChainEnhanced } from '../config';
import { useBananaApprove } from './useBanana';
import { useChainEnhanced } from './useChainEnhanced';

function getSubdomainFactoryContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).SubdomainFactory;
}

// Those DevHelpers are generating some additional RPC requests, remember during debugging
export function DevHelpers() {
  const { chain } = useChainEnhanced();
  const { write: approve } = useBananaApprove({
    spender: getSubdomainFactoryContract(chain)?.address,
    enabled: true,
    allowance: '0',
  });

  useEffect(() => {
    console.info(`Attaching dev helpers... for ${chain?.name}`);
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.bananaApprove = () => {
      approve!();
    };
  }, [approve]);

  return null;
}
