import { ChainType } from '@cyberkongz/config-blockchain';
import { ConnectorName, useChainEnhanced, wagmiConfig } from '@cyberkongz/ui-blockchain';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, useState } from 'react';
import { Connector, useConnect, useSwitchChain, WagmiProvider } from 'wagmi';

import { ConnectWalletModal, SwitchNetworkModal } from '../components';
import { Web3Context } from '../hooks';
import { LANDING_ROUTES_ABSOLUTE } from '../routes';

const queryClient = new QueryClient();

export const ALL_SUPPORTED_CHAIN_TYPES: ChainType[] = ['mainnet', 'polygon', 'ronin'];

// Custom Wrapper around WagmiConfig to display Connect and SwitchingNetwork modals
type _Web3ContextProviderProps = PropsWithChildren & {
  tosLink?: string;
  supportedChainTypes?: ChainType[];
};

function _Web3ContextProvider({
  children,
  supportedChainTypes = ['mainnet', 'polygon'],
  tosLink,
}: _Web3ContextProviderProps) {
  const [expectedChainToConnect, setExpectedChainToConnect] = useState<ChainType>();
  const [isSwitchNetworkOpened, setIsSwitchNetworkOpened] = useState(false);
  const { connectors, connectAsync } = useConnect();
  const { switchChainAsync, reset } = useSwitchChain();
  const { chain } = useChainEnhanced();

  async function handleSwitchNetwork() {
    if (supportedChainTypes?.length > 2) {
      setIsSwitchNetworkOpened(true);
    } else {
      if (chain?.type === 'ronin') {
        const roninConnector = connectors.find(
          (connector) => connector.name === ('RoninWallet' as ConnectorName)
        ) as Connector;

        await connectAsync({ connector: roninConnector });
      } else {
        if (switchChainAsync && chain?.switchToChainId) {
          await switchChainAsync?.({ chainId: chain.switchToChainId });
          reset();
        } else {
          // TO DO HANDLE THAT case for some wallets
          console.log(`Wallet doesn't support switchNetworkAsync`);
        }
      }
    }
  }

  return (
    <Web3Context.Provider
      value={{
        openConnectWalletModal: (expectedChain?: ChainType) =>
          setExpectedChainToConnect(
            expectedChain || supportedChainTypes.length === 1 ? supportedChainTypes[0] : 'mainnet'
          ),
        handleSwitchNetwork,
        supportedChainTypes,
      }}
    >
      {children}
      {expectedChainToConnect ? (
        <ConnectWalletModal
          isOpen={true}
          onClose={() => setExpectedChainToConnect(undefined)}
          tosLink={tosLink || LANDING_ROUTES_ABSOLUTE.TERMS('terms-and-conditions')}
          expectedChain={expectedChainToConnect}
        />
      ) : null}
      <SwitchNetworkModal
        isOpen={isSwitchNetworkOpened}
        onClose={() => setIsSwitchNetworkOpened(false)}
      />
    </Web3Context.Provider>
  );
}

export function Web3Provider({
  config,
  children,
  tosLink,
  supportedChainTypes,
}: { config: typeof wagmiConfig } & PropsWithChildren & _Web3ContextProviderProps) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <_Web3ContextProvider tosLink={tosLink} supportedChainTypes={supportedChainTypes}>
          {children}
        </_Web3ContextProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
