import { MAX_ALLOWANCE, ZERO_ADDRESS } from '@cyberkongz/config-blockchain';
import { Address, erc20Abi, erc721Abi } from 'viem';
import { useAccount, useReadContract, useSimulateContract, useWriteContract } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useWatchReadContract } from '.';

export type UseIsApprovedForAllSharedProps = {
  userAddress?: `0x${string}`;
  spender: `0x${string}`;
  readOnly?: boolean;
};

type UseAllowanceProps = {
  userAddress?: `0x${string}`;
  spender: `0x${string}`;
  readOnly?: boolean;
  chain?: ChainEnhanced;
  contractAddress: Address;
  amount?: bigint;
};

export function useAllowance({
  contractAddress,
  userAddress,
  spender,
  readOnly,
  chain,
  amount,
}: UseAllowanceProps) {
  const { address: connectedAddress } = useAccount();
  const address = userAddress || connectedAddress;

  const {
    data: dataAllowance,
    queryKey,
    ...rest
  } = useReadContract({
    address: contractAddress,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [address || ZERO_ADDRESS, spender || ZERO_ADDRESS],
    chainId: chain?.id,
    query: {
      enabled: !!chain && !!spender && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  const { data: dataSimulate } = useSimulateContract({
    address: contractAddress,
    abi: erc20Abi,
    functionName: 'approve',
    args: [spender, BigInt(MAX_ALLOWANCE)],
    query: {
      enabled: !dataAllowance && !readOnly && amount !== undefined,
    },
  });

  const { data: allowanceTxHash, writeContract: approve } = useWriteContract();

  return {
    hasEnoughAllowance: dataAllowance && amount !== undefined ? dataAllowance >= amount : false,
    allowanceTxHash,
    approve: dataSimulate?.request ? () => approve(dataSimulate.request) : undefined,
  };
}

export function useIsApprovedForAll({
  contractAddress,
  userAddress,
  spender,
  readOnly,
  chain,
}: UseIsApprovedForAllSharedProps & { chain?: ChainEnhanced; contractAddress: Address }) {
  const { address: connectedAddress } = useAccount();
  const address = userAddress || connectedAddress;

  const { data: isApprovedForAll, queryKey } = useReadContract({
    address: contractAddress,
    abi: erc721Abi,
    functionName: 'isApprovedForAll',
    args: [address || ZERO_ADDRESS, spender || ZERO_ADDRESS],
    chainId: chain?.id,
    query: {
      enabled: !!chain && !!spender && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  const { data: dataSimulate } = useSimulateContract({
    address: contractAddress,
    abi: erc721Abi,
    functionName: 'setApprovalForAll',
    args: [spender, true],
    query: {
      enabled: !isApprovedForAll && !readOnly,
    },
  });

  const { data: allowanceTxHash, writeContract: approve } = useWriteContract();

  return {
    hasEnoughAllowance: !!isApprovedForAll,
    allowanceTxHash,
    approve: dataSimulate?.request ? () => approve(dataSimulate.request) : undefined,
  };
}
