import { SystemStyleObject, ThemeOverride } from '@chakra-ui/react';

import { getClipPathBackground, getClipPathBorder, pxToRem, TRANSITIONS } from '../utils';

export const displayButtonSizes = ['sm', 'md', 'lg'] as const;
type DisplayButtonSize = typeof displayButtonSizes[number];

const displayButtonSizeStyles: { [key in DisplayButtonSize]: SystemStyleObject } = {
  sm: {
    fontSize: ['sm'],
    px: 4,
    height: 9,
  },
  md: {
    fontSize: ['sm', 'md'],
    px: 6,
    height: 12,
  },
  lg: {
    fontSize: ['sm', 'md'],
    px: pxToRem(28),
    height: 14,
  },
};

function getDisplayButtonStyles({
  size,
  outline,
}: {
  size: DisplayButtonSize;
  outline?: boolean;
}): SystemStyleObject {
  const sizeStyles = displayButtonSizeStyles[size] || displayButtonSizeStyles['md'];

  return {
    position: 'relative',
    zIndex: 1,
    color: outline ? 'text' : 'textAlt',
    letterSpacing: '0.03em',
    transition: TRANSITIONS.fast,
    ...sizeStyles,
    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
    },
    '&:before': {
      inset: 0,
      background: 'text',
      clipPath: [
        getClipPathBorder(pxToRem(8)),
        getClipPathBorder(size === 'sm' ? pxToRem(8) : pxToRem(12)),
      ],
    },
    '&:after': {
      background: outline ? 'transparent' : 'text',
      transition: TRANSITIONS.fast,
      clipPath: [
        getClipPathBackground(pxToRem(8)),
        getClipPathBackground(size === 'sm' ? pxToRem(8) : pxToRem(12)),
      ],
      top: 0,
      left: 0,
      w: '100%',
      h: '100%',
    },
    '&:hover:enabled': {
      color: outline ? 'textAlt' : 'text',
      '&:after': {
        background: outline ? 'text' : 'transparent',
      },
    },
  };
}

export const systemButtonSizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
type SystemButtonSize = typeof systemButtonSizes[number];

const systemButtonSizeStyles: { [key in SystemButtonSize]: SystemStyleObject } = {
  xs: {
    h: 6,
    fontSize: 'xs',
  },
  sm: {
    h: 8,
    fontSize: 'sm',
  },
  md: {
    h: 10,
    fontSize: 'sm',
  },
  lg: {
    h: 12,
    fontSize: 'md',
    px: 5,
  },
  xl: {
    h: 14,
    fontSize: 'md',
    px: pxToRem(30),
  },
};

type SystemButtonVariants = 'primary' | 'secondary' | 'menu';

const systemSecondaryStyles: SystemStyleObject = {
  color: 'text',
  bg: 'transparent',
  border: '1px',
  borderColor: 'neutral.700',
  _hover: {
    bg: 'rgba(82, 82, 82, 0.2)',
  },
  _disabled: {
    opacity: 1,
    color: 'textTertiary',
    pointerEvents: 'none',
  },
};

const systemButtonVariants: { [key in SystemButtonVariants]: SystemStyleObject } = {
  primary: {
    color: 'textAlt',
    bg: 'primary',
    _hover: {
      color: 'textAlt',
      bg: 'green.400',
    },
    _disabled: {
      opacity: 1,
      color: 'textTertiary',
      pointerEvents: 'none',
      bg: 'textDisabled',
    },
  },
  secondary: systemSecondaryStyles,
  menu: {
    ...systemSecondaryStyles,
    textTransform: 'none',
    h: pxToRem(46),
    fontWeight: 'normal',
    borderRadius: 'lg',
  },
};

function getSystemButtonStyles({
  variant = 'primary',
  size = 'md',
}: {
  variant?: SystemButtonVariants;
  size?: SystemButtonSize;
}): SystemStyleObject {
  const sizeStyles = systemButtonSizeStyles[size] || systemButtonSizeStyles['md'];
  const variantStyles = systemButtonVariants[variant] || systemButtonVariants['primary'];

  return {
    fontWeight: 'medium',
    letterSpacing: pxToRem(0.16), // 0.0114em
    lineHeight: 5,
    px: 4,
    py: `${pxToRem(10)}`,
    borderRadius: 'base',
    transition: TRANSITIONS.standard,
    ...sizeStyles,
    ...variantStyles,
  };
}

const textButtonStyles: SystemStyleObject = {
  p: 0,
  h: 'auto',
  textTransform: 'none',
  fontWeight: 'normal',
  textDecoration: 'underline',
  lineHeight: 'inherit',
  fontSize: 'inherit',
  verticalAlign: 'none',
};

export const Button: ThemeOverride['components'] = {
  Button: {
    baseStyle: {
      cursor: 'pointer',
      borderRadius: '0',
      fontWeight: 'medium',
      fontFamily: 'body',
      lineHeight: 'body',
      textTransform: 'uppercase',
      transitionDuration: '0',
    },
    variants: {
      system: ({ size }) => getSystemButtonStyles({ size, variant: 'primary' }),
      systemSecondary: ({ size }) => getSystemButtonStyles({ size, variant: 'secondary' }),
      systemMenu: ({ size }) => getSystemButtonStyles({ size, variant: 'menu' }),
      // sometimes for those buttons there is a need to manually add hardcoded width because WebkitMask border is cut off due to px rounding
      display: ({ size }) => getDisplayButtonStyles({ size }),
      displayOutline: ({ size }) => getDisplayButtonStyles({ size, outline: true }),
      text: textButtonStyles,
    },
    defaultProps: {
      variant: 'system',
    },
  },
};
