import { APIKongGenkai, getKongGenkaiOwnerData } from '@cyberkongz/api-interfaces';
import { ChainType, CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useIsApprovedForAll, UseIsApprovedForAllSharedProps } from './useAllowance';
import { useChainEnhanced } from './useChainEnhanced';

export function getKongzGenkaiContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).KongzGenkai;
}

export function getKongzGenkaiRoninContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).KongzGenkaiRonin;
}

export function useKongGenkaiEnhancedData(kong?: APIKongGenkai) {
  const { address } = useAccount();
  const enhancedData = useMemo(() => {
    if (!kong) {
      return {
        isOwner: false,
        owner: '',
        onL1: false,
        isLocked: false,
      };
    }

    const { lock_registry, lock_registry_ronin } = kong;

    const { onL1, owner } = getKongGenkaiOwnerData(kong);

    const isOwner = owner?.toLowerCase() === address?.toLowerCase();
    const isLocked = onL1 ? lock_registry.length > 0 : lock_registry_ronin.length > 0;

    return {
      isOwner,
      owner,
      onL1,
      isLocked,
    };
  }, [address, kong]);

  return enhancedData;
}

export function useKongzGenkaiAllowance({
  chainType,
  ...rest
}: UseIsApprovedForAllSharedProps & {
  chainType: ChainType;
}) {
  const { mainnetChain, polygonChain } = useChainEnhanced();

  const { contractAddress, chain } = useMemo(
    () => ({
      contractAddress:
        chainType === 'mainnet'
          ? getKongzGenkaiContract(mainnetChain).address
          : getKongzGenkaiRoninContract(polygonChain).address,
      chain: chainType === 'mainnet' ? mainnetChain : polygonChain,
    }),
    [chainType]
  );

  return useIsApprovedForAll({
    contractAddress,
    chain,
    ...rest,
  });
}
