import {
  ChakraProvider as BaseChakraProvider,
  ChakraProviderProps as BaseChakraProviderProps,
} from '@chakra-ui/provider';
import { baseTheme, Theme, theme as defaultTheme } from '@chakra-ui/theme';
import { Dict } from '@chakra-ui/utils';

export interface ChakraProviderProps extends BaseChakraProviderProps {}

const createChakraProvider = (
  providerTheme: Theme | (Omit<Theme, 'components'> & { components: Dict })
) => {
  return function ChakraProvider({
    children,
    theme = providerTheme,
    ...restProps
  }: ChakraProviderProps) {
    return (
      <BaseChakraProvider theme={theme} {...restProps}>
        {children}
      </BaseChakraProvider>
    );
  };
};

export const CustomChakraProvider = createChakraProvider(defaultTheme);
export const CustomChakraBaseProvider = createChakraProvider(baseTheme);
