import { CONTRACTS_BY_NETWORK, ZERO_ADDRESS } from '@cyberkongz/config-blockchain';
import { useAccount, useReadContract } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useChainEnhanced } from './useChainEnhanced';
import { useWatchReadContract } from './useWatchContractData';

export function getGoldenTicketContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).GoldenTicket;
}

export function getGoldenTicketPolygonContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).GoldenTicketPolygon;
}

export function getGoldenTicketRoninContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).GoldenTicketRonin;
}

export function useGoldenTicketBalance() {
  const { address } = useAccount();
  const { chain, mainnetChain } = useChainEnhanced();

  const { data: goldenTicketBalance, queryKey } = useReadContract({
    ...getGoldenTicketContract(chain),
    functionName: 'balanceOf',
    args: [address || ZERO_ADDRESS, BigInt(1)],
    chainId: mainnetChain?.id,
    query: {
      enabled: !!chain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    goldenTicketBalance,
  };
}

export function useGoldenTicketPolygonBalance() {
  const { address } = useAccount();
  const { chain, polygonChain } = useChainEnhanced();

  const { data: goldenTicketPolygonBalance, queryKey } = useReadContract({
    ...getGoldenTicketPolygonContract(chain),
    functionName: 'balanceOf',
    args: [address || ZERO_ADDRESS, BigInt(1)],
    chainId: polygonChain?.id,
    query: {
      enabled: !!chain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    goldenTicketPolygonBalance,
  };
}
