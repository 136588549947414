export const pxToRem = (px: number) => `${px / 16}rem`;

export const TRANSITIONS = {
  fast: '0.15s ease-in-out',
  standard: '0.3s ease-in-out',
};

export function getClipPathBorder(cut: string) {
  return `polygon(0 ${cut},${cut} 0,100% 0,100% calc(100% - ${cut}),calc(100% - ${cut}) 100%,0 100%,0 ${cut},1px  calc(${cut} + 0.41px),1px calc(100% - 1px),calc(100% - ${cut} - 0.41px) calc(100% - 1px),calc(100% - 1px) calc(100% - ${cut} - 0.41px),calc(100% - 1px) 1px,calc(${cut} + 0.41px) 1px,1px calc(${cut} + 0.41px))`;
}

export function getClipPathBackground(cut: string) {
  return `polygon(${cut} 0%, 100% 0, 100% calc(100% - ${cut}), calc(100% - ${cut}) 100%, 0 100%, 0% ${cut})`;
}
