export const BANANA_COSTS = {
  // only on mainnet
  bio: 100,
  // only on mainnet
  name: 10,
  // only on mainnet
  name_vx: 2,
  // only on mainnet, legacy
  // breed: 600,
};
