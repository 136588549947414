import { SystemStyleObject, ThemeOverride } from '@chakra-ui/react';

import { getClipPathBackground, pxToRem } from '../utils';

const landingCardStyles: SystemStyleObject = {
  bg: 'backgroundLandingCards',
  p: [4, 8, null],
  clipPath: getClipPathBackground(pxToRem(12)),
};

export const transparentCardStyles: SystemStyleObject = {
  borderRadius: 'lg',
  bg: 'backgroundCardsTransparent',
  p: [4, 6, null],
};

export const CustomCard: ThemeOverride['components'] = {
  CustomCard: {
    baseStyle: {
      color: 'textSecondary',
      position: 'relative',
      bg: 'red',
    },
    variants: {
      primary: {
        bg: 'backgroundCards',
        p: 4,
        borderRadius: 'lg',
      },
      transparent: transparentCardStyles,
      transparentSecondary: {
        ...transparentCardStyles,
        bg: 'backgroundCardsTransparentSecondary',
      },
      transparentDark: {
        ...transparentCardStyles,
        bg: 'backgroundCardsTransparentDark',
      },
      landing: landingCardStyles,
      landingTransparent: {
        ...landingCardStyles,
        bg: 'backgroundLandingCardsTransparent',
        backdropFilter: 'blur(16px)',
      },
    },
    defaultProps: {
      variant: 'primary',
    },
  },
};
