import { Icon, IconProps } from '@chakra-ui/react';

export function MetamaskIcon(props: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x="0"
      y="0"
      version="1.1"
      width="60px"
      height="60px"
      viewBox="0 0 318.6 318.6"
      {...props}
    >
      <defs>
        <style>
          {`.st1,.st6{fill:#e4761b;stroke:#e4761b;strokeLinecap:round;strokeLinejoin:round}.st6{fill:#f6851b;stroke:#f6851b}`}
        </style>
      </defs>
      <path
        fill="#e2761b"
        stroke="#e2761b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m274.1 35.5-99.5 73.9L193 65.8z"
      />
      <path
        d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z"
        className="st1"
      />
      <path
        d="m103.6 138.2-15.8 23.9 56.3 2.5-2-60.5zm111.3 0-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"
        className="st1"
      />
      <path
        fill="#d7c1b3"
        stroke="#d7c1b3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m211.8 247.4-33.9-16.5 2.7 22.1-.3 9.3zm-105 0 31.5 14.9-.2-9.3 2.5-22.1z"
      />
      <path
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z"
      />
      <path
        fill="#cd6116"
        stroke="#cd6116"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m106.8 247.4 4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1 20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
      />
      <path
        fill="#e4751f"
        stroke="#e4751f"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m87.8 162.1 23.6 46-.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z"
      />
      <path
        d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
        className="st6"
      />
      <path
        fill="#c0ad9e"
        stroke="#c0ad9e"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m180.3 262.3.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
      />
      <path
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z"
      />
      <path
        fill="#763d16"
        stroke="#763d16"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m278.3 114.2 8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
      />
      <path
        d="m267.2 153.5-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4 3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
        className="st6"
      />
    </Icon>
  );
}

export function WalletConnectIcon(props: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="62px"
      height="38.75px"
      viewBox="0 0 480 332"
      {...props}
    >
      <path
        d="m126.613 93.9842c62.622-61.3123 164.152-61.3123 226.775 0l7.536 7.3788c3.131 3.066 3.131 8.036 0 11.102l-25.781 25.242c-1.566 1.533-4.104 1.533-5.67 0l-10.371-10.154c-43.687-42.7734-114.517-42.7734-158.204 0l-11.107 10.874c-1.565 1.533-4.103 1.533-5.669 0l-25.781-25.242c-3.132-3.066-3.132-8.036 0-11.102zm280.093 52.2038 22.946 22.465c3.131 3.066 3.131 8.036 0 11.102l-103.463 101.301c-3.131 3.065-8.208 3.065-11.339 0l-73.432-71.896c-.783-.767-2.052-.767-2.835 0l-73.43 71.896c-3.131 3.065-8.208 3.065-11.339 0l-103.4657-101.302c-3.1311-3.066-3.1311-8.036 0-11.102l22.9456-22.466c3.1311-3.065 8.2077-3.065 11.3388 0l73.4333 71.897c.782.767 2.051.767 2.834 0l73.429-71.897c3.131-3.065 8.208-3.065 11.339 0l73.433 71.897c.783.767 2.052.767 2.835 0l73.431-71.895c3.132-3.066 8.208-3.066 11.339 0z"
        fill="#3396ff"
      />
    </Icon>
  );
}

export function CoinbaseWalletIcon(props: IconProps) {
  return (
    <Icon
      width="56px"
      height="55px"
      viewBox="0 0 56 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 0.375C42.9823 0.375 55.125 12.5177 55.125 27.5C55.125 42.4823 42.9823 54.625 28 54.625C13.0177 54.625 0.875 42.4823 0.875 27.5C0.875 12.5177 13.0177 0.375 28 0.375Z"
        fill="#0052FF"
      />
      <path
        d="M28.0053 37.0361C22.7392 37.0361 18.4692 32.7714 18.4692 27.5C18.4692 22.2286 22.7392 17.9639 28.0053 17.9639C32.7257 17.9639 36.6461 21.4075 37.3984 25.9106H47.0034C46.1928 16.1202 37.997 8.42773 28 8.42773C17.4679 8.42773 8.92773 16.9679 8.92773 27.5C8.92773 38.0321 17.4679 46.5723 28 46.5723C37.997 46.5723 46.1928 38.8798 47.0034 29.0894H37.3931C36.6355 33.5925 32.7257 37.0361 28.0053 37.0361Z"
        fill="white"
      />
    </Icon>
  );
}
