import { useLocalStorage } from '@cyberkongz/ui';
import { createContext, PropsWithChildren, useContext } from 'react';

interface UserSettings {
  audioEnabled: boolean;
}

type UserSettingsContextState = UserSettings & {
  toggleAudio: () => void;
  setAudio: (audioEnabled: boolean) => void;
};

const UserSettingsContext = createContext<UserSettingsContextState>({} as UserSettingsContextState);

const LS_USER_SETTINGS = 'userSettings';

export function UserSettingsProvider({ children }: PropsWithChildren) {
  const [userSettings, setUserSettings] = useLocalStorage<UserSettings>(LS_USER_SETTINGS, {
    audioEnabled: true,
  });

  function toggleAudio() {
    setUserSettings({ audioEnabled: !userSettings?.audioEnabled });
  }

  function setAudio(audioEnabled: boolean) {
    setUserSettings({ audioEnabled });
  }

  return (
    <UserSettingsContext.Provider value={{ ...userSettings, toggleAudio, setAudio }}>
      {children}
    </UserSettingsContext.Provider>
  );
}

export function useUserSettings() {
  const context = useContext(UserSettingsContext);

  if (context === undefined) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }

  return context;
}
