import { Box, Container, Flex, Grid, SystemStyleObject } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { pxToRem, TRANSITIONS } from '../../theme';
import { AppLink } from '../AppLink';
import { LogoIcon, SocialIcons } from '../icons';

export function useIsMenuScrolling() {
  const [isScrolling, setIsScrolling] = useState(false);

  function handleScroll() {
    const scrolledTo = window.scrollY;

    if (scrolledTo === 0) {
      setIsScrolling(false);
    } else if (!isScrolling) {
      setIsScrolling(true);
    }
  }

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [isScrolling]);

  return { isScrolling };
}

function Line({ sx }: { sx: SystemStyleObject }) {
  return (
    <Box
      sx={{
        width: pxToRem(28),
        height: pxToRem(2),
        mx: 'auto',
        transition: TRANSITIONS.standard,
        ...sx,
      }}
    />
  );
}

export function HamburgerMenuWrapper({
  color,
  opened,
  setOpened,
  children,
  colorSocials,
  colorHamburgerIconOpened,
  sxContainer,
  sxWrapper,
}: {
  color?: string;
  colorHamburgerIconOpened: string;
  colorSocials: string;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  sxContainer: SystemStyleObject;
  sxWrapper?: SystemStyleObject;
} & PropsWithChildren) {
  return (
    <Box position="relative" zIndex={opened ? 2 : 1} sx={sxWrapper}>
      <Box
        onClick={() => setOpened(!opened)}
        sx={{ position: 'relative', zIndex: opened ? 2 : 1, cursor: 'pointer' }}
      >
        <Grid gap={pxToRem(7)} py={pxToRem(3)}>
          <Line
            sx={{
              bg: color || 'text',
              ...(opened && {
                transform: `translateY(${pxToRem(9)}) rotateZ(45deg)`,
                bg: colorHamburgerIconOpened,
              }),
            }}
          />
          <Line
            sx={{
              bg: color || 'text',
              ...(opened && { opacity: '0', bg: colorHamburgerIconOpened }),
            }}
          />
          <Line
            sx={{
              bg: color || 'text',
              ...(opened && {
                transform: `translateY(${pxToRem(-9)}) rotateZ(-45deg)`,
                bg: colorHamburgerIconOpened,
              }),
            }}
          />
        </Grid>
      </Box>
      <Flex
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          width: ['100%', pxToRem(500)],
          px: [4, 8],
          pt: 24,
          pb: 16,
          flexDirection: 'column',
          justifyContent: 'space-between',
          transition: TRANSITIONS.standard,
          transform: `translateX(${opened ? '0' : 100}%)`,
          overflowY: 'auto',
          ...sxContainer,
        }}
      >
        <Grid gap={7}>{children}</Grid>
        <Box>
          <Box my={8}>
            <Box
              h={1}
              w={16}
              bg={colorSocials}
              boxShadow="0px 0px 25px var(--chakra-colors-white)"
            />
            <Box sx={{ borderBottom: '1px', borderColor: colorSocials }} />
          </Box>
          <SocialIcons />
        </Box>
      </Flex>
    </Box>
  );
}

export function MenuWrapper({
  sxContainer,
  sxMenu,
  sxLogo,
  variantMenu,
  children,
  isScrolling,
  bannerComponent,
}: {
  sxContainer?: SystemStyleObject;
  sxMenu?: SystemStyleObject;
  sxLogo?: SystemStyleObject;
  variantMenu?: string;
  isScrolling?: boolean;
  bannerComponent?: ReactNode;
} & PropsWithChildren) {
  return (
    <Box
      as="header"
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bg: isScrolling ? 'background' : 'transparent',
        zIndex: 'menu',
        transition: TRANSITIONS.standard,
        ...sxContainer,
      }}
    >
      {bannerComponent}
      <Container
        variant={variantMenu || 'md'}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 6,
          pb: 4,
          top: 0,
          left: 0,
          right: 0,
          ...sxMenu,
        }}
      >
        <AppLink href="/">
          <LogoIcon
            sx={{
              [`@media screen and (max-width: ${pxToRem(480)})`]: {
                width: pxToRem(40),
                height: 'auto',
              },
              ...sxLogo,
            }}
          />
        </AppLink>
        {children}
      </Container>
    </Box>
  );
}
