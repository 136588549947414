import { useEffect, useState } from 'react';

function getStorageValue<T>(key: string): T | undefined {
  const storageValue = localStorage.getItem(key);

  return storageValue && storageValue !== 'undefined' ? JSON.parse(storageValue) : undefined;
}

export function useLocalStorage<T>(key: string | undefined, defaultValue: T) {
  const [value, setValue] = useState(defaultValue);

  // Loading value from local storage on mount
  useEffect(() => {
    if (key) {
      const loadedFromLocalStorage = getStorageValue<T>(key);

      if (loadedFromLocalStorage !== undefined) {
        setValue(loadedFromLocalStorage);
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue));
      }
    }
  }, [key]);

  // updating local storage record only if it differs from default value
  // otherwise it overwrites saved value with default value
  useEffect(() => {
    if (key && value !== defaultValue) {
      localStorage.setItem(
        key,
        JSON.stringify(
          value,
          (_, value) => (typeof value === 'bigint' ? value.toString() : value) // return everything else unchanged
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [value, setValue] as const;
}
