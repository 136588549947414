import { CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { useEffect, useState } from 'react';
import { decodeEventLog, parseAbiItem, TransactionReceipt } from 'viem';
import { usePublicClient, useWaitForTransactionReceipt } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useChainEnhanced } from './useChainEnhanced';

function getVRFCoordinatorRonin(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).VRFCoordinatorRonin;
}

export function parseRandomSeedRequestedLogs(
  { logs }: Pick<TransactionReceipt, 'logs'>,
  chain?: ChainEnhanced
) {
  const { abi, address } = getVRFCoordinatorRonin(chain);
  let requestHash: `0x${string}` | undefined;

  logs.forEach((log) => {
    try {
      const { data, topics, address: _emitterAddress } = log;

      if (address.toLowerCase() !== _emitterAddress.toLowerCase()) {
        return;
      }

      const parsedLog = decodeEventLog({
        abi,
        data,
        topics,
      });

      if (parsedLog.eventName === 'RandomSeedRequested') {
        requestHash = parsedLog.args.reqHash;
      }
    } catch (err) {
      console.error('Something went wrong while parsing data', log, err);
    }
  });

  return requestHash;
}

export function useVRFSeedFulfilled() {
  const { roninChain } = useChainEnhanced();
  const [requestHash, setRequestHash] = useState<`0x${string}`>();
  const [fulfilledTxHash, setFulfilledTxHash] = useState<`0x${string}`>();
  const publicClient = usePublicClient({ chainId: roninChain?.id });
  const [VRFCheckFromBlock, setVRFCheckFromBlock] = useState<bigint>();

  const vrfCoordinatorRonin = getVRFCoordinatorRonin(roninChain);

  useEffect(() => {
    if (VRFCheckFromBlock && requestHash && publicClient) {
      const getLogs = async () => {
        try {
          const logs = await publicClient.getLogs({
            address: vrfCoordinatorRonin.address,
            event: parseAbiItem(
              `event RandomSeedFulfilled(bytes32 indexed requestHash, uint256 randomSeed, uint256 requestValue, uint256 refundAmount, uint256 paymentAmount, uint256 constantFee, bool callbackResult)`
            ),
            args: {
              requestHash,
            },
            fromBlock: VRFCheckFromBlock,
            toBlock: VRFCheckFromBlock + BigInt(199),
          });

          if (logs.length > 0) {
            const fulfilledTxHash = logs[0].transactionHash;
            console.info('Callback found VRF Coordinator', fulfilledTxHash);
            setFulfilledTxHash(fulfilledTxHash);
            setVRFCheckFromBlock(undefined);
          } else {
            console.info('No callback found, re-checking in couple secs...');
          }
        } catch (err) {
          console.error('Public Client Get Logs error', err);
        }
      };

      const requestDataInterval = setInterval(getLogs, 7 * 1000);

      return () => {
        clearInterval(requestDataInterval);
      };
    }
  }, [VRFCheckFromBlock, requestHash, publicClient]);

  const { data: dataFulfilled } = useWaitForTransactionReceipt({
    hash: fulfilledTxHash,
    chainId: roninChain?.id,
  });

  function handleVRFSeedRequested(receipt: TransactionReceipt | undefined) {
    if (receipt) {
      setRequestHash(parseRandomSeedRequestedLogs(receipt, roninChain));
      setVRFCheckFromBlock(receipt.blockNumber);
    }
  }

  function resetVRFSeedFulfilled() {
    setRequestHash(undefined);
    setVRFCheckFromBlock(undefined);
  }

  return {
    dataFulfilled,
    handleVRFSeedRequested,
    resetVRFSeedFulfilled,
  };
}
