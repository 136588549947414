import { Box, Button, Flex, Grid, SystemStyleObject, Text, useStyleConfig } from '@chakra-ui/react';
import {
  AccountButton,
  AppLink,
  ChevronDownIcon,
  DropdownBase,
  DropdownItem,
  HamburgerMenuWrapper,
  LANDING_ROUTES,
  LinkArrowIcon,
  MenuWrapper,
  OtherAppsDropdown,
  pxToRem,
  SHARED_ROUTES,
  TRANSITIONS,
  useDropdownWithHover,
  useIsMenuScrolling,
} from '@cyberkongz/ui';
import { useSharedUI } from '@features/sharedUI/SharedUIProvider';
import { useState } from 'react';

const BUY_DROPDOWN_ITEMS: { href: string; label: string }[] = [
  {
    label: 'Genesis',
    href: SHARED_ROUTES.OPENSEA_COLLECTION_GENESIS,
  },
  {
    label: 'Baby',
    href: SHARED_ROUTES.OPENSEA_COLLECTION_BABY,
  },
  {
    label: 'VX',
    href: SHARED_ROUTES.MAVIS_MARKET_COLLECTION_VX,
  },
  {
    label: 'Genkai',
    href: SHARED_ROUTES.OPENSEA_COLLECTION_GENKAI,
  },
];

function BuyDropdown({ color, borderColor }: { color?: string; borderColor?: string }) {
  const { handleOnMouseEnter, handleOnMouseLeave, handleOnClick, dropdownOpened } =
    useDropdownWithHover();

  return (
    <Box
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
      sx={{ position: 'relative', display: ['none', 'block'] }}
    >
      <Button
        variant="systemMenu"
        size="sm"
        sx={{
          w: pxToRem(88),
          color,
          borderColor: borderColor || 'neutral.700',
          svg: {
            w: pxToRem(13),
            transition: 'transform 0.3s ease-in-out',
            transform: `rotateZ(${dropdownOpened ? 180 : 0}deg)`,
          },
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text pr={3}>Buy</Text>
          <ChevronDownIcon />
        </Flex>
      </Button>
      <DropdownBase dropdownOpened={dropdownOpened}>
        {BUY_DROPDOWN_ITEMS.map(({ label, href }) => (
          <DropdownItem key={label} href={href}>
            <Flex alignItems="center" justifyContent="space-between">
              {label} <LinkArrowIcon width={pxToRem(19)} height="auto" />
            </Flex>
          </DropdownItem>
        ))}
      </DropdownBase>
    </Box>
  );
}

const MENU_LINKS = [
  { title: 'Home', href: LANDING_ROUTES.HOME },
  { title: 'About', href: LANDING_ROUTES.ABOUT },
  { title: 'Kollections', href: LANDING_ROUTES.KOLLECTIONS },
  { title: '$BANANA', href: LANDING_ROUTES.BANANA },
  { title: 'Blueprint', href: LANDING_ROUTES.BLUEPRINT },
  { title: 'Play & kollect', href: LANDING_ROUTES.PLAY_AND_KOLLECT },
  { title: 'Press Kit', href: LANDING_ROUTES.PR_KIT },
  { title: 'Blog', href: LANDING_ROUTES.BLOG },
];

function Line({ sx }: { sx: SystemStyleObject }) {
  return (
    <Box
      sx={{
        width: pxToRem(28),
        height: pxToRem(2),
        mx: 'auto',
        transition: TRANSITIONS.standard,
        ...sx,
      }}
    />
  );
}

function HamburgerMenu({ color }: { color?: string }) {
  const [opened, setOpened] = useState(false);
  const [buyOpened, setBuyOpened] = useState(false);
  const buyTextStyles = useStyleConfig('Link', { variant: 'menu' });

  return (
    <HamburgerMenuWrapper
      {...{
        color,
        opened,
        setOpened,
        colorSocials: 'neutral.100',
        colorHamburgerIconOpened: 'text',
        sxContainer: {
          bg: 'url(/static/menu/bg.jpg) center/cover no-repeat',
        },
      }}
    >
      <>
        {MENU_LINKS.map(({ title, href }) => (
          <AppLink key={href} href={href} variant="menu" onClick={() => setOpened(false)}>
            {title}
          </AppLink>
        ))}
        <Box display={['block', 'none']}>
          <Flex
            alignItems="center"
            onClick={() => setBuyOpened(!buyOpened)}
            sx={{
              cursor: 'pointer',
              svg: {
                transition: TRANSITIONS.standard,
                transform: `rotateZ(${buyOpened ? 180 : 0}deg)`,
              },
            }}
          >
            <Box __css={buyTextStyles} pr={3}>
              Buy
            </Box>
            <ChevronDownIcon />
          </Flex>
          {buyOpened ? (
            <Grid mt={3} gap={3} fontSize="xl">
              {BUY_DROPDOWN_ITEMS.map(({ href, label }) => (
                <AppLink href={href}>{label}</AppLink>
              ))}
            </Grid>
          ) : null}
        </Box>
      </>
    </HamburgerMenuWrapper>
  );
}

export function Menu() {
  const { isScrolling } = useIsMenuScrolling();
  const { kongVXTheme } = useSharedUI();
  const color = kongVXTheme && !isScrolling ? kongVXTheme.text : undefined;
  const borderColor = kongVXTheme?.isDark && !isScrolling ? kongVXTheme.text : undefined;

  return (
    <MenuWrapper isScrolling={isScrolling} sxLogo={{ color }}>
      <Flex
        sx={{
          gap: [4, 6],
          alignItems: 'center',
        }}
      >
        <BuyDropdown color={color} />
        <AccountButton
          sxDropdownBaseContainer={{
            right: [pxToRem(-90), 0],
          }}
          sxButton={{ color, borderColor: borderColor || 'neutral.700' }}
        />
        <OtherAppsDropdown color={color} />
        <HamburgerMenu color={color} />
      </Flex>
    </MenuWrapper>
  );
}
