import { Flex, SystemStyleObject } from '@chakra-ui/react';

import { BlurIcon, DiscordIcon, OpenSeaIcon, TwitterIconWithLink } from './Icons';

export function SocialIcons(sx?: SystemStyleObject) {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: 8,
        fontSize: '0px',
        'svg, svg path': { fill: 'currentColor' },
        ...sx,
      }}
    >
      <OpenSeaIcon />
      <BlurIcon />
      <DiscordIcon />
      <TwitterIconWithLink />
    </Flex>
  );
}
