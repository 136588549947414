export const colors = {
  text: '#FFF',
  textSecondary: '#C6C6C6', // neutral.200
  textTertiary: '#8D8D8D', // neutral.400
  textDisabled: '#525252', // neutral.600

  textAlt: '#161616',

  background: '#000',
  backgroundSecondary: '#161616', // neutral.900
  backgroundTertiary: '#262626', // neutral.800

  backgroundCards: '#1C1C1C',
  backgroundCardsTransparent: 'rgba(111, 111, 111, 0.2)',
  backgroundCardsTransparentSecondary: 'rgba(255, 255, 255, 0.04)',

  backgroundCardsTransparentDark: 'rgba(22, 22, 22, 0.85)',

  backgroundLandingCards: '#101010',
  backgroundLandingCardsTransparent: 'rgba(95, 95, 95, 0.15)',

  // brand colors
  primary: '#45F08C',
  secondary: '#F21DDD',
  tertiary: '#23D5DB',

  modalOverlay: 'rgba(0, 0, 0, 0.6)',

  // from red palette
  error: '#FA4D56',

  // colors palette
  neutral: {
    25: '#F4F4F4',
    50: '#E8E8E8',
    100: '#DBDBDB',
    200: '#C6C6C6',
    300: '#A8A8A8',
    400: '#8D8D8D',
    500: '#6F6F6F',
    600: '#525252',
    700: '#393939',
    800: '#262626',
    850: '#1C1C1C',
    900: '#161616',
  },

  green: {
    25: '#E4FFED',
    50: '#CEFFE4',
    100: '#AAFFD1',
    200: '#7BFFB4',
    300: '#45F08C',
    400: '#24C774',
    500: '#15985F',
    600: '#077149',
    700: '#005338',
    800: '#003D2C',
    900: '#002E23',
  },

  pink: {
    25: '#FFF3FE',
    50: '#FFDCFF',
    100: '#FFB5FF',
    200: '#FF80FF',
    300: '#FF42F6',
    400: '#F21DDD',
    500: '#C90CAF',
    600: '#97007E',
    700: '#6E0058',
    800: '#4D003C',
    900: '#330026',
  },

  teal: {
    25: '#DEFFFC',
    50: '#BCF7F6',
    100: '#99F0F0',
    200: '#71EBEB',
    300: '#49E5E5',
    400: '#23D5DB',
    500: '#00AEC2',
    600: '#017C8F',
    700: '#005E70',
    800: '#004452',
    900: '#032931',
  },

  red: {
    50: '#FFF1F1',
    100: '#FFD7D9',
    200: '#FFB3B8',
    300: '#FF8389',
    400: '#FA4D56',
    500: '#DA1E28',
    600: '#A2191F',
    700: '#750E13',
    800: '#520408',
    900: '#2D0709',
  },

  yellow: {
    50: '#FCF4D6',
    100: '#FDDC69',
    200: '#F1C21B',
    300: '#D2A106',
    400: '#B28600',
    500: '#8E6A00',
    600: '#684E00',
    700: '#483700',
    800: '#302400',
    900: '#241B00',
  },
};
