import { ComponentStyleConfig, SystemStyleObject, ThemeOverride } from '@chakra-ui/react';

import { paragraphSizeTextStyles } from '../textStyles';
import { pxToRem, TRANSITIONS } from '../utils';
import { Button } from './button';
import { CustomCard } from './customCard';
import { Heading } from './heading';

const inputFieldStyles: SystemStyleObject = {
  borderRadius: 'base',
  border: 'thin',
  bg: 'backgroundSecondary',
  _focus: {
    borderColor: 'text',
  },
  _placeholder: {
    color: 'textTertiary',
  },
  _invalid: {
    borderColor: 'error',
  },
};

const inputStyles: ComponentStyleConfig = {
  variants: {
    primary: {
      field: inputFieldStyles,
    },
  },
  sizes: {
    sm: {
      field: {
        py: 1,
        h: 10,
        fontSize: ['sm'],
      },
    },
    md: {
      field: {
        py: 2,
        h: pxToRem(52),
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
};

export const components: ThemeOverride['components'] = {
  Link: {
    variants: {
      footer: {
        fontWeight: 'medium',
      },
      menu: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        fontSize: '2xl',
        textTransform: 'uppercase',
        color: 'neutral.100',
        transition: TRANSITIONS.standard,
        _hover: {
          textDecoration: 'none',
          color: 'primary',
          textShadow: '0px 0px 25px var(--chakra-colors-primary)',
        },
      },
      displayHover: {
        '&:hover': {
          textDecorationThickness: pxToRem(2),
          textUnderlineOffset: '0.3em',
        },
      },
      noHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  Container: {
    baseStyle: {
      px: [4, 4],
      maxWidth: [pxToRem(480), pxToRem(1240)],
    },
    variants: {
      md: {
        maxWidth: [pxToRem(480), pxToRem(1360)],
      },
      lg: {
        maxWidth: [pxToRem(480), pxToRem(1440)],
      },
      full: {
        maxWidth: [pxToRem(480), '100%'],
      },
    },
  },
  Input: { ...inputStyles },
  NumberInput: { ...inputStyles },
  Select: { ...inputStyles },
  Textarea: {
    baseStyle: {
      resize: 'none',
    },
    variants: {
      primary: inputFieldStyles,
    },
    sizes: {
      md: {
        py: 3,
        height: 6,
      },
    },
    defaultProps: {
      variant: 'primary',
      size: 'md',
    },
  },
  Checkbox: {
    baseStyle: {
      icon: {
        color: 'background',
      },
      control: {
        h: 4,
        w: 4,
        border: '1px',
        borderColor: 'textSecondary',
        borderRadius: '1px',
        _checked: {
          bg: 'primary',
          borderColor: 'primary',
          _hover: {
            bg: 'primary',
            borderColor: 'primary',
          },
        },
        _indeterminate: {
          bg: 'primary',
          borderColor: 'primary',
          _hover: {
            bg: 'primary',
            borderColor: 'primary',
          },
        },
        _disabled: {
          borderColor: 'textTertiary',
          bg: 'transparent',
        },
      },
    },
  },
  Switch: {
    defaultProps: {
      colorScheme: 'green',
    },
    baseStyle: {
      track: {
        alignItems: 'center',
        bg: 'neutral.600',
        _checked: {
          bg: 'green.500',
        },
      },
      thumb: {
        position: 'relative',
        right: pxToRem(-1),
        w: pxToRem(14),
        h: pxToRem(14),
      },
    },
  },
  FormLabel: {
    baseStyle: {
      ...paragraphSizeTextStyles['p-sm'],
      fontSize: 'sm',
    },
  },
  FormError: {
    baseStyle: {
      text: {
        ...paragraphSizeTextStyles['p-sm'],
        color: 'error',
        fontSize: 'sm',
      },
    },
  },
  Tooltip: {
    baseStyle: {
      p: 4,
      borderRadius: 'md',
      fontWeight: 'normal',
    },
  },
  ...Heading,
  ...Button,
  // Card component was added in v2.4 and is multipart component but we were already using it as a singlepart
  ...CustomCard,
};
