import { ChainType } from '@cyberkongz/config-blockchain';
import { Address } from 'viem';
import { coinbaseWallet, injected, mock, walletConnect } from 'wagmi/connectors';

const CONNECTOR_NAMES = [
  'MetaMask',
  'Injected',
  'WalletConnect',
  'Coinbase Wallet',
  'Ronin Wallet',
  'Mock Connector',
] as const;

export type ConnectorName = typeof CONNECTOR_NAMES[number];

const ETHConnectors: ConnectorName[] = [
  'MetaMask',
  'Injected',
  'WalletConnect',
  'Coinbase Wallet',
  'Mock Connector',
];

export const CONNECTORS_BY_NETWORK: { [key in ChainType]: ConnectorName[] } = {
  mainnet: ETHConnectors,
  polygon: ETHConnectors,
  ronin: ['Injected', 'Ronin Wallet', 'Mock Connector'],
};

export const connectors = [
  injected(),
  walletConnect({
    projectId: '3680f9b81e5e24dd389f631774e04e0b',
  }),
  coinbaseWallet({
    appName: 'Cyberkongz',
  }),
];

if (process.env.NEXT_PUBLIC_MOCKED_WALLET_ADDRESS) {
  // @ts-ignore
  connectors.push(mock({ accounts: [process.env.NEXT_PUBLIC_MOCKED_WALLET_ADDRESS as Address] }));
}
