import { Box, Flex, Grid, Image, Text, useBreakpoint } from '@chakra-ui/react';

import { useDropdownWithHover } from '../../hooks';
import { APP_ROUTES_ABSOLUTE, LANDING_ROUTES_ABSOLUTE, SHARED_ROUTES } from '../../routes';
import { pxToRem, TRANSITIONS } from '../../theme';
import { AppLink } from '../AppLink';
import {
  ArrowDownIcon,
  ClawIcon,
  CloseIcon,
  ENSIcon,
  GenkaiIcon,
  KreatorProgramIcon,
  MarketIcon,
  NiakeaIcon,
  OtherAppsIcon,
  ShieldIcon,
  TransferIcon,
} from '../icons';

const OTHER_APPS = [
  {
    icon: <GenkaiIcon />,
    title: 'Genkai',
    description: 'Explore Genkai and start your journey via the Emaki Dashboard',
    href: SHARED_ROUTES.GENKAI,
  },
  {
    icon: <TransferIcon />,
    title: 'Bridge',
    description: 'Transfer your assets',
    href: SHARED_ROUTES.BRIDGE,
  },
  {
    icon: <MarketIcon />,
    title: 'KongzMart',
    description: 'WhiteList Marketplace',
    href: SHARED_ROUTES.KONGZ_MART,
  },
  {
    icon: <ShieldIcon />,
    title: 'Guardian',
    description: 'Secure your assets',
    href: SHARED_ROUTES.GUARDIAN,
  },
  {
    icon: <ClawIcon />,
    title: 'The Klaw',
    description: 'Redeem your Golden Ticket',
    href: SHARED_ROUTES.KLAW,
  },
  {
    icon: <ENSIcon />,
    title: 'ENS',
    description: 'Manage your ENS',
    href: SHARED_ROUTES.ENS,
  },
  {
    icon: <NiakeaIcon />,
    title: 'Niakea',
    description: 'Visit the Kongz home continent',
    href: LANDING_ROUTES_ABSOLUTE.NIAKEA,
  },
  {
    icon: <KreatorProgramIcon />,
    title: 'Kreator Program',
    description: 'Apply for a position',
    href: LANDING_ROUTES_ABSOLUTE.KREATOR_PROGRAM,
  },
];

export function OtherAppsDropdown({ color = 'text' }: { color?: string }) {
  const { handleOnMouseEnter, handleOnMouseLeave, handleOnClick, dropdownOpened } =
    useDropdownWithHover();
  const breakpoint = useBreakpoint();

  return (
    <Box
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
      sx={{ position: 'relative', zIndex: dropdownOpened ? 2 : 1 }}
    >
      <Box sx={{ position: 'relative', cursor: 'pointer', zIndex: dropdownOpened ? 2 : 1 }}>
        {breakpoint === 'base' && dropdownOpened ? (
          <CloseIcon />
        ) : (
          <OtherAppsIcon
            sx={{
              rect: {
                stroke: color,
                transition: TRANSITIONS.fast,
              },
              '&:hover': {
                rect: {
                  fill: color,
                },
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          position: ['fixed', 'absolute'],
          display: dropdownOpened ? 'grid' : 'none',
          width: ['100%', pxToRem(650), pxToRem(948)],
          top: [0, undefined],
          bottom: 0,
          pt: [0, pxToRem(10)],
          transform: ['none', 'translateY(100%)'],
          right: [0, -2],
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            color: 'textSecondary',
            overflowY: ['auto', 'initial'],
          }}
        >
          <Box
            sx={{
              bg: 'backgroundSecondary',
              px: 4,
              py: [undefined, 6],
              pt: [20, undefined],
              pb: [10, undefined],
              flex: 1,
              borderRight: ['none', 'thin'],
            }}
          >
            <Grid
              columnGap={4}
              rowGap={6}
              sx={{
                fontSize: 'md',
                gridTemplateRows: ['repeat(4, max-content)'],
                gridAutoFlow: [undefined, undefined, 'column'],
                mx: ['auto', 0],
                maxWidth: [pxToRem(245), 'none'],
              }}
            >
              {OTHER_APPS.map(({ icon, title, description, href }) => (
                <AppLink key={href} href={href}>
                  <Flex sx={{ svg: { color: 'text', maxHeight: pxToRem(24) } }}>
                    {icon}
                    <Box ml={3} mt={pxToRem(-2)}>
                      <Text color="neutral.100" mb={1} fontWeight="medium">
                        {title}
                      </Text>
                      <Text fontSize="sm" letterSpacing="0.01em">
                        {description}
                      </Text>
                    </Box>
                  </Flex>
                </AppLink>
              ))}
            </Grid>
          </Box>
          <Flex
            sx={{
              bg: 'backgroundTertiary',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                flex: 1,
                py: 6,
                px: [4, 6],
                boxSizing: ['content-box', 'border-box'],
                mx: ['auto', 0],
              }}
            >
              <Image
                src={`${SHARED_ROUTES.CDN}/static/menu/jungle_adventure.png`}
                alt="Kongz facing portal"
                mb={4}
                maxW={pxToRem(255)}
              />
              <Text
                sx={{
                  color: 'neutral.100',
                  fontFamily: 'heading',
                  fontSize: ['md', 'lg'],
                  fontWeight: 'bold',
                  mb: 1,
                }}
              >
                ADVENTURE RUN
              </Text>
              <Text fontSize="sm">A part of the Play & Kollect ecosystem</Text>
            </Box>
            <Box borderTop="thin" px={[4, 6]} py="4" mt={3}>
              <AppLink
                href={APP_ROUTES_ABSOLUTE.JUNGLE_ADVENTURE}
                sx={{
                  display: 'block',
                  maxWidth: [pxToRem(245), '100%'],
                  mx: ['auto', 0],
                }}
              >
                <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text sx={{ fontWeight: 'medium', textTransform: 'uppercase' }}>
                    Explore the Jungle
                  </Text>
                  <Box sx={{ svg: { transform: 'rotate(-90deg)', height: 4 } }}>
                    <ArrowDownIcon />
                  </Box>
                </Flex>
              </AppLink>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
