import { useRouter } from 'next/router';
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

export type KongVXTheme = {
  isDark?: boolean;
  text?: string;
  textTab?: string;
  label?: string;
  bgEffect?: string;
};

type History = { prev: string | undefined; current: string } | undefined;

interface SharedUIContextState {
  // CSS theme across UI depending on Kong bg
  kongVXTheme?: KongVXTheme;
  setKongVXTheme: Dispatch<SetStateAction<KongVXTheme | undefined>>;
  // used for back button on kong pages
  history?: History;
}

const SharedUIContext = createContext<SharedUIContextState>({} as SharedUIContextState);

export function SharedUIProvider({ children }: PropsWithChildren) {
  const [kongVXTheme, setKongVXTheme] = useState<KongVXTheme>();
  const [history, setHistory] = useState<History>();
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    if (history === undefined) {
      setHistory({ prev: undefined, current: asPath });
    } else {
      setHistory((prevState) => ({ prev: prevState?.current, current: asPath }));
    }
  }, [asPath]);

  return (
    <SharedUIContext.Provider
      value={{
        kongVXTheme,
        setKongVXTheme,
        history,
      }}
    >
      {children}
    </SharedUIContext.Provider>
  );
}

export function useSharedUI() {
  const context = useContext(SharedUIContext);

  if (context === undefined) {
    throw new Error('useSharedUI must be used within a SharedUIProvider');
  }

  return context;
}
