import { CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { ChainEnhanced } from '@cyberkongz/ui-blockchain';

export function getEmakiConsumableContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).EmakiConsumable;
}

export function getEmakiEquippableContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).EmakiEquippable;
}
