import { normalize } from 'viem/ens';
import { useAccount, useEnsAvatar, useEnsName } from 'wagmi';

import { useChainEnhanced } from './useChainEnhanced';

export function useEnsData() {
  const { address } = useAccount();
  const { mainnetChain } = useChainEnhanced();

  const { data: ensName } = useEnsName({
    address,
    chainId: mainnetChain?.id,
    // force refresh data on app mount
    query: {
      staleTime: 0,
    },
  });
  const { data: ensAvatar } = useEnsAvatar({
    name: ensName ? normalize(ensName) : undefined,
    chainId: mainnetChain?.id,
    // force refresh data on app mount
    query: {
      staleTime: 0,
      enabled: !!ensName,
    },
  });

  return {
    ensName,
    ensAvatar,
  };
}
