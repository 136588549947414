import { SystemStyleObject, ThemeOverride } from '@chakra-ui/react';

import { pxToRem } from '../utils';

export const primaryHeadingSizes = ['3xs', '2xs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
type PrimaryHeadingSize = typeof primaryHeadingSizes[number];

// Adding `null` as last element - sometimes there are inconsistent UI renders if size is not specified
// and last element in responsive table is overwritten from default size
const primaryHeadingSizeStyles: { [key in PrimaryHeadingSize]: SystemStyleObject } = {
  '3xs': {
    fontSize: ['sm', 'md', null],
    lineHeight: ['1.5rem', '1.75rem'],
    letterSpacing: pxToRem(0.48),
  },
  '2xs': {
    fontSize: ['md', 'lg', null],
    lineHeight: ['1.5rem', '1.75rem'],
    letterSpacing: pxToRem(0.48),
  },
  xs: {
    fontSize: ['lg', 'xl', null],
    lineHeight: ['1.75rem', '1.875rem'],
    letterSpacing: [pxToRem(0.48), pxToRem(0.4)],
  },
  sm: {
    fontSize: ['xl', '2xl', null],
    lineHeight: ['1.875rem', '2.25rem'],
    letterSpacing: [pxToRem(0.4), pxToRem(0.32)],
  },
  md: {
    fontSize: ['xl', '2.5xl', null],
    lineHeight: ['1.875rem', '2.625rem'],
    letterSpacing: [pxToRem(0.4), 0],
  },
  lg: {
    fontSize: ['2xl', '3xl', null],
    lineHeight: ['2.25rem', '3rem'],
    letterSpacing: [pxToRem(0.32), 0],
  },
  xl: {
    fontSize: ['2xl', '4xl', null],
    lineHeight: ['2.25rem', '3.75rem'],
    letterSpacing: [pxToRem(0.32), 0],
  },
};

function getPrimaryHeadingStyles({ size = 'xl' }: { size?: PrimaryHeadingSize }) {
  const sizeStyles = primaryHeadingSizeStyles[size] || primaryHeadingSizeStyles['xl'];

  return {
    ...sizeStyles,
  };
}

export const displayHeadingSizes = ['sm', 'lg'] as const;
type DisplayHeadingSize = typeof displayHeadingSizes[number];

// Last null item => look on displayPrimarySizes
const displayHeadingSizeStyles: { [key in DisplayHeadingSize]: SystemStyleObject } = {
  sm: {
    fontSize: ['2xl', '4xl', null],
  },
  lg: {
    fontSize: ['5xl', '8xl', null],
  },
};

function getDisplayHeadingStyles({
  color,
  size = 'lg',
}: {
  color: 'text' | 'primary' | 'secondary' | 'tertiary';
  size?: DisplayHeadingSize;
}): SystemStyleObject {
  const textShadow = `0px 0px 25px var(--chakra-colors-${color})`;
  const sizeStyles = displayHeadingSizeStyles[size] || displayHeadingSizeStyles['lg'];

  return {
    fontWeight: 'normal',
    fontFamily: 'hyperwaveOne',
    lineHeight: 0.9,
    color,
    textShadow,
    ...sizeStyles,
  };
}

export const Heading: ThemeOverride['components'] = {
  Heading: {
    baseStyle: {
      fontFamily: 'heading',
      lineHeight: 'base',
      color: 'text',
    },
    variants: {
      primary: ({ size }) => getPrimaryHeadingStyles({ size }),
      display: ({ size }) => getDisplayHeadingStyles({ color: 'text', size }),
      displayPrimary: ({ size }) => getDisplayHeadingStyles({ color: 'primary', size }),
      displaySecondary: ({ size }) => getDisplayHeadingStyles({ color: 'secondary', size }),
      displayTertiary: ({ size }) => getDisplayHeadingStyles({ color: 'tertiary', size }),
    },
    defaultProps: {
      variant: 'primary',
    },
  },
};
