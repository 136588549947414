import { ChainType, parseRoninAddress } from '@cyberkongz/config-blockchain';
import { useMemo } from 'react';
import { isAddress } from 'viem';

export function useIsValidAddress(
  address: string,
  { chainType }: { chainType?: ChainType } = { chainType: 'mainnet' }
) {
  const isValidAddress = useMemo(
    () =>
      address === ''
        ? false
        : isAddress(chainType === 'ronin' ? parseRoninAddress(address) : address),
    [address, chainType]
  );

  return isValidAddress;
}
