import { Icon, IconProps } from '@chakra-ui/react';

export function RoninIconColor(props: IconProps) {
  return (
    <Icon
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1716_39908)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.44922 3.01099V13.812C3.44922 14.015 3.49822 14.211 3.58922 14.393C3.68022 14.575 3.81322 14.729 3.97422 14.862L9.25922 18.95C9.44822 19.097 9.67922 19.174 9.91722 19.174C10.1552 19.174 10.3862 19.097 10.5752 18.95L15.8602 14.862C16.0212 14.736 16.1542 14.575 16.2452 14.393C16.3362 14.211 16.3852 14.008 16.3852 13.812V3.01099C16.3852 2.65399 16.2382 2.31099 15.9792 2.05899C15.7132 1.80699 15.3632 1.66699 14.9922 1.66699H4.85622C4.48522 1.66699 4.12822 1.80699 3.86922 2.05899C3.61022 2.31099 3.45622 2.65399 3.45622 3.01099H3.44922Z"
          fill="#1273EA"
        />
        <path
          d="M14.2856 7.72178V5.02678C14.2856 4.66978 14.1386 4.32678 13.8796 4.07478C13.6136 3.82278 13.2636 3.67578 12.8926 3.67578H6.94964C6.57864 3.67578 6.22164 3.81578 5.96264 4.07478C5.70364 4.32678 5.55664 4.66978 5.55664 5.02678V12.8388C5.55664 13.0418 5.60564 13.2448 5.69664 13.4198C5.78764 13.6018 5.92064 13.7628 6.08164 13.8888L8.24464 15.5618C8.27264 15.5828 8.30064 15.5898 8.33564 15.5968C8.37064 15.5968 8.39864 15.5968 8.43364 15.5828C8.46164 15.5688 8.48964 15.5478 8.50364 15.5198C8.52464 15.4918 8.53164 15.4638 8.53164 15.4288V9.90578C8.53164 9.85678 8.55264 9.81478 8.58764 9.78678C8.62264 9.75178 8.66464 9.73778 8.71364 9.73778H10.2886C10.5616 9.73778 10.8346 9.84278 11.0306 10.0318C11.2266 10.2208 11.3386 10.4798 11.3386 10.7458V15.4288C11.3386 15.4568 11.3456 15.4918 11.3666 15.5198C11.3876 15.5478 11.4086 15.5688 11.4366 15.5828C11.4996 15.6108 11.5696 15.6038 11.6186 15.5618L13.7816 13.8888C13.9496 13.7628 14.0756 13.6018 14.1666 13.4198C14.2576 13.2378 14.3066 13.0418 14.3066 12.8388V10.4098C14.3066 10.0528 14.1596 9.70978 13.9006 9.45778C13.6346 9.20578 13.2846 9.06578 12.9066 9.06578C13.2776 9.06578 13.6346 8.92578 13.8936 8.66678C14.1526 8.41478 14.2996 8.07178 14.2996 7.71478L14.2856 7.72178ZM10.2676 8.39378H8.69264C8.65064 8.39378 8.60164 8.37278 8.56664 8.34478C8.53164 8.31678 8.51764 8.26778 8.51764 8.22578V5.19478C8.51764 5.15278 8.53864 5.11078 8.56664 5.07578C8.60164 5.04778 8.64364 5.02678 8.69264 5.02678H11.1356C11.1846 5.02678 11.2266 5.04778 11.2616 5.07578C11.2966 5.11078 11.3106 5.15278 11.3106 5.19478V7.38578C11.3106 7.65178 11.1986 7.91078 11.0026 8.09978C10.8066 8.28878 10.5406 8.39378 10.2606 8.39378H10.2676Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1716_39908">
          <rect width="17.5" height="17.5" fill="white" transform="translate(1.16699 1.66699)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
