import {
  Box,
  HTMLChakraProps,
  SystemStyleObject,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';

import { pxToRem } from '../theme';

export const cardOutlineStyles: SystemStyleObject = {
  border: 'thin',
  borderColor: 'neutral.700',
  '&:after, &:before': {
    position: 'absolute',
    display: 'block',
    content: '""',
    w: pxToRem(31),
    borderTop: 'inherit',
  },
  '&:after': {
    top: 0,
    left: 0,
    transform: `translateX(${pxToRem(-5)}) rotateZ(135deg)`,
  },
  '&:before': {
    bottom: 0,
    right: 0,
    transform: `translateX(${pxToRem(5)}) rotateZ(135deg)`,
  },
};

export function getExpandedCardOutlineStyles(
  isExpanded: boolean,
  borderColor?: string
): SystemStyleObject {
  return {
    position: 'relative',
    border: '1px solid transparent',
    ...(isExpanded && cardOutlineStyles),
    borderColor: borderColor || 'none',
  };
}

// TO DO AFTER RELEASES DELETE THIS AND PUT JUST CARD INSIDE THEME
export function Card(props: HTMLChakraProps<'div'> & ThemingProps) {
  const { variant, ...rest } = props;
  const styles = useStyleConfig('CustomCard', { variant });

  return <Box __css={styles} {...rest} />;
}
