import { Box, BoxProps, Flex, FlexProps, SystemStyleObject } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { colors, pxToRem } from '../theme';

export function TabsItem<T>({
  activeTab,
  setActiveTab,
  tab,
  children,
  sx,
  color,
  noShadow,
}: {
  activeTab: T;
  setActiveTab: (activeTab: T) => void;
  tab: T;
  sx?: SystemStyleObject;
  noShadow?: boolean;
} & PropsWithChildren &
  BoxProps) {
  const isActive = activeTab === tab;

  return (
    <Box
      onClick={() => setActiveTab(tab)}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        fontWeight: 'medium',
        textTransform: 'uppercase',
        pb: [2, 4],
        opacity: isActive ? 1 : 0.5,
        color: isActive ? color || 'primary' : color || 'text',
        textShadow:
          !noShadow && isActive ? `0px 0px 30px ${color ? 'transparent' : colors.primary}` : 'none',
        ...sx,
      }}
    >
      {children}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: pxToRem(2),
          bg: isActive ? color || 'primary' : 'transparent',
          boxShadow:
            !noShadow && isActive
              ? `0px -4px 22px 2px ${color ? 'transparent' : colors.primary}`
              : 'none',
        }}
      />
    </Box>
  );
}

export function TabsContainer(props: FlexProps) {
  return <Flex gap={[5, 10]} borderBottom="thin" {...props} />;
}
