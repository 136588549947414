import {
  CloseButtonProps,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  SystemStyleObject,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

import { pxToRem } from '../theme';
import { CloseIcon } from './icons';

type ModalStyles = {
  styles?: {
    content?: SystemStyleObject;
    closeIcon?: SystemStyleObject;
    body?: SystemStyleObject;
    header?: SystemStyleObject;
  };
};

export type BaseModalProps = Pick<ChakraModalProps, 'isOpen' | 'onClose'> & ModalStyles;

export type AppModalProps = ChakraModalProps &
  PropsWithChildren &
  ModalStyles & {
    headerComponent?: ReactNode;
  };

export function ModalClose(props: CloseButtonProps) {
  return (
    <ModalCloseButton boxSize={6} top={[2, 6]} right={[2, 6]} zIndex={5} {...props}>
      <CloseIcon boxSize="inherit" />
    </ModalCloseButton>
  );
}

export function Modal({ children, headerComponent, styles, ...rest }: AppModalProps) {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay sx={{ bg: 'modalOverlay' }} />
      <ModalContent
        sx={{
          bg: 'backgroundSecondary',
          borderRadius: 'base',
          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          margin: 'auto',
          ...styles?.content,
        }}
      >
        {headerComponent ? (
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            sx={styles?.header}
          >
            {headerComponent}
            <ModalClose top={0} right={0} position="relative" sx={styles?.closeIcon} />
          </ModalHeader>
        ) : (
          <ModalClose sx={styles?.closeIcon} />
        )}
        <ModalBody color="textSecondary" sx={styles?.body}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}

export function ModalWithForm({ styles, ...rest }: AppModalProps) {
  return (
    <Modal
      {...rest}
      isCentered
      styles={{
        header: {
          py: 6,
          px: [4, 6],
          ...styles?.header,
        },
        content: {
          minW: ['auto', pxToRem(600)],
          borderRadius: 'lg',
          ...styles?.content,
        },
        body: {
          px: [4, 6],
          pb: [5, 6],
          pt: 0,
          ...styles?.body,
        },
        closeIcon: styles?.closeIcon,
      }}
    />
  );
}
