import { AppPageSEOTags, PageSEOTags as _PageSEOTags } from '@cyberkongz/ui';

import SEOTags from './seo.json';

export const SITE_URL = 'https://www.cyberkongz.com';
const DEFAULT_TITLE = SEOTags.home.title;
const DEFAULT_OG_IMG = `${SITE_URL}/static/og.jpg`;
const DEFAULT_OG_IMG_ALT = 'CyberKongz';

type PageKey = keyof typeof SEOTags;

export function PageSEOTags(props: AppPageSEOTags<PageKey>) {
  return (
    <_PageSEOTags<PageKey>
      siteUrl={SITE_URL}
      defaultImageUrl={DEFAULT_OG_IMG}
      defaultImageAlt={DEFAULT_OG_IMG_ALT}
      defaultTitle={DEFAULT_TITLE}
      seoTagsMapping={SEOTags}
      {...props}
    />
  );
}
